import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

const Volume1 = () => {
  return (
    <div className="container">
      <Helmet>
        <title> The Shoyaright! </title>
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@zuffshoya" />
        <meta name="twitter:title" content="The Shoyaright!: Volume 1" />
        <meta
          name="twitter:image"
          content="http://www.shoya.co/static/shoyaright_icon-ada66d9717351d5e161cdd4c5d83603b.png"
        />
        <meta name="twitter:image:alt" content="The Shoyaright! Logo" />
      </Helmet>
      <div id="shoyarightChomsky" />
      <div id="lastCat" className="categories">
        MUSIC POLITICS CINEMA ART TELEVISION CUISINE TECHNOLOGY SPORTS NATURE
      </div>
      <div className="articleList">
        <p>
          <Link to="/shoyaright/vol1/mt-shoya"> The Battle of Mt. Shoya </Link>
        </p>
        <p>
          <Link to="/shoyaright/vol1/watching-animals"> Watching Animals </Link>
        </p>
        <p>
          <Link to="/shoyaright/vol1/blm">
            {" "}
            Black Lives Matter: Press Release{" "}
          </Link>
        </p>
        <p>
          <Link to="/shoyaright/vol1/silver-lining">
            {" "}
            America's Silver Lining{" "}
          </Link>
        </p>
        <p>
          <Link to="/shoyaright/vol1/quarantine-recipes">
            3 Quarantine Recipes
          </Link>
        </p>
        <p>
          <Link to="/shoyaright/vol1/coronavirus-vaccine">
            Shoya, Inc. to Develop Coronavirus Vaccine
          </Link>
        </p>
        <p>
          <Link to="/shoyaright/vol1/collis-dbz">
            Collis Billiards As DBZ Characters
          </Link>
        </p>
        <p>
          <Link to="/shoyaright/vol1/atlantique">
            What Mati Diop & Lil Wayne Share
          </Link>
        </p>
        <p>
          <Link to="/shoyaright/vol1/chomsky-sunni">Chomsky&#39;s Sunni</Link>
        </p>
        <p>
          <Link to="/shoyaright/vol1/baby-rappers">
            The Best &#34;Baby&#34; Rapper?
          </Link>
        </p>
        <p>
          <Link to="/shoyaright/vol1/shoya-casio">
            Shoya, Inc. to Acquire Casio
          </Link>
        </p>
        <p>
          <Link to="/shoyaright/vol1/drogas">In Search of Drogas</Link>
        </p>
        <p>
          <Link to="/shoyaright/vol1/us-iran">
            Should the U.S. Go to War with Iran?
          </Link>
        </p>
      </div>
      <div className="copyright">
        <p>&copy; Shoya, Inc. 2020 - &infin;</p>
      </div>
    </div>
  )
}

export default Volume1
